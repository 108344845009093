
import React from 'react'

const Logo = () => {
  return (
    <div className="pt-6 lg:pt-0 flex justify-center">
      <a href="/" aria-label="Home">
        <img
          width={150}
          src="https://3375779.fs1.hubspotusercontent-na1.net/hubfs/3375779/EmailTemplates/ROLLER_Logo.png"
          alt="ROLLER - All-in-on Venue Management software"
          title="Roller"
        />
      </a>
    </div>
  );
}

export default Logo