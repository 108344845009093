import React from "react";

export interface FooterProps {
  text: string;
}

const Footer = ({ text }: FooterProps) => {
  return (
    <footer className="mx-auto w-full max-w-2xl space-y-10 pb-16 lg:max-w-5xl">
      <div className="flex flex-col items-center justify-between gap-5 border-t border-neutral-10 pt-8 sm:flex-row">
        <p className="text-xs text-primary-50">
          {text || "© Copyright 2023. All rights reserved."}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
