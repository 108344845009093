import React from "react";
import Signature from "./Components/Signature";

import "./App.css";
import Footer from "./Components/Footer";
import Logo from "./Components/Logo";

export interface BannerType {
  value: string;
  text: string;
}

export interface BannerProps {
  imageUrl: string;
  linkUrl: string;
}

export interface SignatureProps {
  fullName: string;
  position: string;
  phone?: string;
  banners?: Record<string, BannerProps>;
  bannerType?: BannerType[];
  selectedBannerType?: string;
}

interface State extends SignatureProps {
  copied: boolean;
}

const initialState: State = {
  fullName: "John Smith",
  position: "Sales & Marketing Director",
  phone: "(800) 555-0299",
  banners: {
    productSpotlight: {
      imageUrl:
        "https://3375779.fs1.hubspotusercontent-na1.net/hubfs/3375779/EmailTemplates/596x96_2x.png",
      linkUrl: "https://www.roller.software/blog/tag/product-updates",
    },
    gx: {
      imageUrl:
        "https://3375779.fs1.hubspotusercontent-na1.net/hubfs/3375779/EmailTemplates/Banner_Generic.png",
      linkUrl: "https://www.roller.software/the-guest-experience-show/",
    },
    acquaPark: {
      imageUrl:
        "https://3375779.fs1.hubspotusercontent-na1.net/hubfs/3375779/EmailTemplates/Banner_Image_AquaPark@2x.png",
      linkUrl: "https://www.roller.software/blog/gc-aqua-park",
    },
    climbzone: {
      imageUrl:
        "https://3375779.fs1.hubspotusercontent-na1.net/hubfs/3375779/EmailTemplates/Banner_Image_Climbzone@2x.png",
      linkUrl: "https://www.roller.software/blog/case-study-climbzone",
    },
    industryReport: {
      imageUrl:
        "https://3375779.fs1.hubspotusercontent-na1.net/hubfs/3375779/EmailTemplates/signature_Industry_report_596x96.png",
      linkUrl: "https://www.roller.software/2025-benchmark-report",
    },
  },
  copied: false,
  bannerType: [
    {
      value: "productSpotlight",
      text: "Product Spotlight",
    },
    {
      value: "gx",
      text: "GX Score",
    },
    {
      value: "acquaPark",
      text: "GC Aqua Park Grows Online Reviews",
    },
    {
      value: "climbzone",
      text: "ClimbZone reduces payroll costs",
    },
    {
      value: "industryReport",
      text: "2025 Benchmark Report",
    },
  ],
  selectedBannerType: "productSpotlight",
};

const App = () => {
  const [state, setState] = React.useState<State>(initialState);
  const [selectedBannerType, setSelectedBannerType] = React.useState("");

  const BANNER_OPTIONS = [
    { value: "", text: "Choose a banner option" },
    { value: "productSpotlight", text: "Product spotlight" },
    { value: "gx", text: "GX show podcast" },
    { value: "acquaPark", text: "GC Aqua Park Grows Online Reviews" },
    { value: "climbzone", text: "ClimbZone reduces payroll costs" },
    { value: "industryReport", text: "2025 Benchmark Report" },
  ];

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    if (name === "bannerType") {
      setSelectedBannerType(value);
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const hasEnoughData = state.fullName && state.position;
  const signature = hasEnoughData ? (
    <Signature
      fullName={state.fullName}
      position={state.position}
      phone={state.phone}
      banners={state.banners}
      bannerType={state.bannerType}
      selectedBannerType={selectedBannerType || "productSpotlight"}
    />
  ) : (
    <div>Please, add the data</div>
  );

  // TODO: Update the code below to use the Clipboard API instead
  const copyToClipboard = (e: any) => {
    e.preventDefault();
    const copyText = document.querySelector(".signature");

    if (!copyText) {
      console.error("Copy text element not found");
      return;
    }

    const range = document.createRange();
    range.selectNode(copyText);

    const windowSelection = window.getSelection();
    if (windowSelection) {
      windowSelection.removeAllRanges();
      windowSelection.addRange(range);
    }

    try {
      const successful = document.execCommand("copy");
      if (successful) {
        console.log("Success");
        setState((prevState) => ({
          ...prevState,
          copied: true,
        }));
      } else {
        console.log("Copy command was unsuccessful");
      }
    } catch (err) {
      console.error("Copy command failed", err);
    }
  };

  const isStateChanged = () => {
    return JSON.stringify(state) === JSON.stringify(initialState);
  };

  const clearState = () => {
    setState(initialState);
    setSelectedBannerType("");
  };

  return (
    <div className="h-full lg:ml-72 xl:ml-80">
      <header className="contents lg:pointer-events-none lg:fixed lg:inset-0 lg:z-40 lg:flex">
        <div className="contents lg:pointer-events-auto lg:block lg:w-72 lg:overflow-y-auto px-6 pb-8 pt-7 bg-neutral-10">
          <Logo />
          <nav className="px-6 lg:px-0 lg:mt-10 lg:block">
            <div className="my-10">
              <h2 className="text-primary-50 font-bold text-2xl">
                Enter your details
              </h2>
            </div>
            <form
              className="grid grid-cols-1 gap-6"
              noValidate
              autoComplete="off"
            >
              <label className="block">
                <span className="text-primary-50 font-bold">Full name *</span>
                <input
                  type="text"
                  className="
                  mt-0
                  block
                  w-full
                  px-0.5
                  py-2
                  border-0 border-b-[1px] border-neutral-100
                  focus:ring-0 focus:border-primary-50
                  bg-transparent
                "
                  placeholder="e.g. John Smith"
                  value={state.fullName}
                  name={"fullName"}
                  onChange={handleChange}
                />
              </label>
              <label className="block">
                <span className="text-primary-50 font-bold">Position *</span>
                <input
                  type="text"
                  className="
                  mt-0
                  block
                  w-full
                  px-0.5
                  py-2
                  border-0 border-b-[1px] border-neutral-100
                  focus:ring-0 focus:border-primary-50
                  bg-transparent
                "
                  placeholder="e.g. Sales & Marketing Director"
                  value={state.position}
                  name={"position"}
                  onChange={handleChange}
                />
              </label>
              <label className="block">
                <span className="text-primary-50 font-bold">Phone</span>
                <input
                  type="text"
                  className="
                  mt-0
                  block
                  w-full
                  px-0.5
                  py-2
                  border-0 border-b-[1px] border-neutral-200
                  focus:ring-0 focus:border-primary-50
                  bg-transparent
                "
                  placeholder="e.g. (800) 555-0299"
                  value={state.phone}
                  name={"phone"}
                  onChange={handleChange}
                />
              </label>
              <label className="block">
                <span className="text-primary-50 font-bold">
                  What type of banner is it? *
                </span>
                <select
                  name={"bannerType"}
                  value={selectedBannerType || "productSpotlight"}
                  onChange={handleChange}
                  className="
                    block
                    w-full
                    mt-0
                    px-0.5
                    py-2
                    border-0 border-b-[1px] border-neutral-200
                    focus:ring-0 focus:border-primary-50
                    bg-transparent
                  "
                >
                  {BANNER_OPTIONS.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </label>
              <br />
              <div className="flex justify-center gap-3">
                <button
                  onClick={copyToClipboard}
                  className="
                  relative inline-flex items-center cursor-pointer no-underline tracking-tight leading-6 font-semibold text-md transition ease-in-out duration-300 w-full sm:w-auto justify-center  bg-transparent rounded-3xl text-primary-50 border-0"
                >
                  {state.copied ? "Copied" : "Copy to clipboard"}
                </button>
                <button
                  disabled={isStateChanged()}
                  onClick={clearState}
                  className="
                relative inline-flex items-center no-underline tracking-tight leading-6 font-semibold text-md transition ease-in-out duration-300 w-full sm:w-auto justify-center bg-transparent rounded-3xl py-2 px-4 text-secondary-50 border-0 disabled:opacity-25"
                >
                  Clear all
                </button>
              </div>
            </form>
          </nav>
        </div>
      </header>
      <div className="relative flex h-full flex-col px-4 pt-14 sm:px-6 lg:px-8">
        <main className="flex-auto py-16">
          <article className="prose dark:prose-invert">
            <div className="mx-auto w-full space-y-10 pb-16 lg:max-w-5xl inset-0 -z-10 overflow-hidden">
              <div className="mb-20">
                <h1 className="scroll-mt-24 text-2xl font-bold text-secondary-50">
                  Getting started
                </h1>
                <p className="text-primary-50">
                  To add a signature to your email, enter your information on
                  the left, choose the banner type from the select field, and
                  then click the "Copy to clipboard" button. This will copy all
                  the necessary markup for you to add to your email service.
                </p>
              </div>
              <div>{signature}</div>
            </div>
          </article>
        </main>
        <Footer text="© Copyright 2023. All rights reserved." />
      </div>
    </div>
  );
};

export default App;
