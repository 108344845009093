import { SignatureProps } from "../App";

const Signature = ({ fullName, position, phone, banners, bannerType, selectedBannerType}: SignatureProps) => {
  return (
    <table
      cellPadding={0}
      cellSpacing={0}
      className={"signature"}
      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
    >
      <tbody>
        <tr>
          <td style={{ backgroundColor: "#ff490C", padding: 2 }}></td>
          <td>
            <table cellPadding={0} cellSpacing={0}>
              <tbody>
                <tr>
                  <td
                    style={{
                      fontSize: 18,
                      fontWeight: 800,
                      color: "#ff490C",
                      paddingTop: 2,
                      paddingLeft: 24,
                    }}
                  >
                    {fullName}
                  </td>
                </tr>
              </tbody>
            </table>
            <table cellPadding={0} cellSpacing={0}>
              <tbody>
                <tr>
                  <td
                    colSpan={3}
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#033180",
                      paddingLeft: 24,
                    }}
                  >
                    {position}
                  </td>
                </tr>
              </tbody>
            </table>
            <table cellPadding={24} cellSpacing={0}>
              <tbody>
                <tr>
                  <td
                    colSpan={3}
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#033180",
                      paddingLeft: 24,
                    }}
                  >
                    {phone ? (
                      <>
                        <strong>M. </strong>
                        {phone}
                      </>
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>
            <table cellPadding={0} cellSpacing={0}>
              <tbody>
                <tr>
                  <td colSpan={3} style={{ paddingLeft: 24 }}>
                    <a
                      href={"https://www.roller.software/"}
                      style={{ display: "flex" }}
                    >
                      <img
                        width={146}
                        src="https://3375779.fs1.hubspotusercontent-na1.net/hubfs/3375779/EmailTemplates/ROLLER_Logo.png"
                        alt="ROLLER - All-in-on Venue Management software"
                        title="Roller"
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <table cellPadding={0} cellSpacing={0}>
              <tbody>
                <tr>
                  <td colSpan={3} style={{ paddingLeft: 24 }}>
                    <div
                      style={{
                        display: "flex",
                        fontSize: 12,
                        color: "#033180",
                        letterSpacing: -0.16,
                        fontWeight: 400,
                        paddingTop: 8,
                      }}
                    >
                      <a
                        href={"https://www.roller.software/"}
                        title="ROLLER - All-in-on Venue Management software"
                        style={{ color: "#033180" }}
                      >
                        Website
                      </a>
                      <span style={{ marginLeft: 3, marginRight: 3 }}>|</span>
                      <a
                        href={"https://www.linkedin.com/company/roller/"}
                        title="ROLLER - LinkedIn"
                        style={{ color: "#033180" }}
                      >
                        LinkedIn
                      </a>
                      <span style={{ marginLeft: 3, marginRight: 3 }}>|</span>
                      <a
                        href={
                          "https://www.roller.software/the-guest-experience-show/"
                        }
                        title="ROLLER - The Guest Experience"
                        style={{ color: "#033180" }}
                      >
                        Podcast
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table cellPadding={0} cellSpacing={0}>
              <tbody>
                <tr>
                  <td colSpan={3} style={{ paddingTop: 24 }}>
                    <a
                      href={
                        selectedBannerType &&
                        banners &&
                        banners[selectedBannerType]
                          ? banners[selectedBannerType].linkUrl
                          : ""
                      }
                      style={{ display: "flex" }}
                      title="ROLLER - All-in-on Venue Management software"
                    >
                      <img
                        src={
                          selectedBannerType &&
                          banners &&
                          banners[selectedBannerType]
                            ? banners[selectedBannerType].imageUrl
                            : ""
                        }
                        width={596}
                        alt="ROLLER - All-in-on Venue Management software"
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <table cellPadding={0} cellSpacing={0}>
              <tbody>
                <tr>
                  <td
                    colSpan={3}
                    style={{
                      fontSize: 12,
                      fontWeight: 800,
                      color: "#033180",
                      paddingLeft: 24,
                      paddingTop: 24,
                      paddingBottom: 18,
                    }}
                  >
                    Check out our consistently high industry ratings:
                  </td>
                </tr>
              </tbody>
            </table>
            <table cellPadding={0} cellSpacing={0}>
              <tbody>
                <tr>
                  <td colSpan={3} style={{ paddingLeft: 24 }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: 12,
                        color: "#033180",
                        letterSpacing: -0.16,
                        fontWeight: 400,
                        paddingTop: 8,
                      }}
                    >
                      <a
                        href={
                          "https://www.softwareadvice.com/ticketing/roller-profile/"
                        }
                        title="ROLLER - Sofware Advice"
                        style={{
                          color: "#033180",
                          paddingRight: 24,
                          verticalAlign: "middle",
                        }}
                      >
                        <img
                          src="https://brand-assets.softwareadvice.com/badge/2e7ea1c3-d3f1-489d-a14b-dece8ecbbae9.png"
                          alt="ROLLER - Sofware Advice"
                          width={66}
                        />
                      </a>
                      <a
                        href={
                          "https://www.g2.com/products/roller-software/reviews?utm_source=rewards-badge"
                        }
                        title="ROLLER - G2"
                        style={{
                          color: "#033180",
                          paddingRight: 24,
                          verticalAlign: "middle",
                        }}
                      >
                        <img
                          src="https://3375779.fs1.hubspotusercontent-na1.net/hubfs/3375779/EmailTemplates/G2@2x.png"
                          alt="ROLLER - G2"
                          width={66}
                        />
                      </a>
                      <a
                        href={"https://www.capterra.com/p/164715/ROLLER/"}
                        title="ROLLER - Capterra"
                        style={{
                          color: "#033180",
                          paddingRight: 24,
                          verticalAlign: "middle",
                        }}
                      >
                        <img
                          src="https://brand-assets.capterra.com/badge/044749f3-19d8-45d7-be16-09168f70e395.png"
                          alt="ROLLER - Capterra"
                          width={88}
                        />
                      </a>
                      <a
                        href={
                          "https://sourceforge.net/software/product/ROLLER/"
                        }
                        title="ROLLER - Sourceforge User Reviews"
                        style={{
                          color: "#033180",
                          paddingRight: 24,
                          verticalAlign: "middle",
                        }}
                      >
                        <img
                          src="https://b.sf-syn.com/badge_img/2951339/leader-fall-white?achievement=leader-seasonal-2024-fall&amp;variant_id=sf&amp;r=https://www.roller.software/"
                          alt="ROLLER - Sourceforge User Reviews"
                          width={77}
                        />
                      </a>
                      <a
                        href={
                          "https://www.getapp.com/customer-management-software/a/roller/"
                        }
                        title="ROLLER - GetApp"
                        style={{
                          color: "#033180",
                          paddingRight: 24,
                          verticalAlign: "middle",
                        }}
                      >
                        <img
                          src="https://capterra.s3.amazonaws.com/assets/images/gdm-badges/GA-Badge-CategoryLeaders-2023-FullColor-Positive.png"
                          alt="ROLLER - GetApp"
                          width={88}
                        />
                      </a>
                      <a
                        href={"https://slashdot.org/software/p/ROLLER/"}
                        title="ROLLER - Slashdot User Reviews"
                        style={{ color: "#033180", verticalAlign: "middle" }}
                      >
                        <img
                          src="https://b.sf-syn.com/badge_img/2951339/leader-fall-white?achievement=leader-seasonal-2024-fall&amp;variant_id=sd&amp;r=https://www.roller.software/"
                          alt="ROLLER - Slashdot User Reviews"
                          width={66}
                        />
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Signature;
